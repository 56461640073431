import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  Paper,
  ThemeProvider,
  createTheme,
  Tabs,
  Tab,
  useMediaQuery,
  Button,
  CircularProgress,
} from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import LoanInput from "./LoanInput";
import LoanResults from "./LoanResults";
import LoanComparison from "./LoanComparison";
import FinancialImpact from "./FinancialImpact";
import Refinancing from "./Refinancing";
import LoanForgivenessChecker from "./LoanForgivenessChecker";
import LoanOptions from "./LoanOptions";

const theme = createTheme({
  palette: {
    primary: { main: "#0a1f44" },
    secondary: { main: "#f8f4e3" },
    background: { default: "#fafafa", paper: "#ffffff" },
    text: { primary: "#333333", secondary: "#4a4a4a" },
    error: { main: "#800020" },
  },
  typography: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' },
});

const currentRates = {
  USA: {
    federal: {
      subsidized: 3.73,
      unsubsidized: { undergraduate: 3.73, graduate: 5.28 },
      plus: 6.28,
    },
    private: { average: 5.5 },
  },
  UK: { plan1: 1.1, plan2: 4.5, postgraduate: 5.4 },
};

const StudentLoanCalculator = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStep, setCurrentStep] = useState(0);
  const [loanData, setLoanData] = useState(null);
  const [calculatedResults, setCalculatedResults] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);

  const calculateLoanDetails = (inputData) => {
    setIsCalculating(true);
    setTimeout(() => {
      const monthlyRate = inputData.interestRate / 100 / 12;
      const numberOfPayments = inputData.loanTerm * 12;

      // Calculate accrued interest period
      const accruedInterestPeriod = Math.max(
        0,
        inputData.yearsBetweenLoanAndGraduation - inputData.gracePeriod / 12
      );

      const accruedInterest =
        inputData.loanAmount *
        (inputData.interestRate / 100) *
        accruedInterestPeriod;
      const totalLoanAmount = Number(inputData.loanAmount) + accruedInterest;

      const monthlyPayment =
        (totalLoanAmount *
          monthlyRate *
          Math.pow(1 + monthlyRate, numberOfPayments)) /
        (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
      const totalRepayment = monthlyPayment * numberOfPayments;
      const totalInterest = totalRepayment - totalLoanAmount;

      const amortizationSchedule = calculateAmortizationSchedule(
        totalLoanAmount,
        monthlyRate,
        monthlyPayment,
        numberOfPayments
      );

      setCalculatedResults({
        monthlyPayment,
        totalRepayment,
        totalInterest,
        accruedInterest,
        totalLoanAmount,
        accruedInterestPeriod,
        amortizationSchedule,
      });
      setIsCalculating(false);
    }, 1000);
  };

  const calculateAmortizationSchedule = (
    balance,
    monthlyRate,
    monthlyPayment,
    numberOfPayments
  ) => {
    const schedule = [];
    let remainingBalance = balance;

    for (let i = 1; i <= numberOfPayments; i++) {
      const interest = remainingBalance * monthlyRate;
      const principal = monthlyPayment - interest;
      remainingBalance = Math.max(remainingBalance - principal, 0);

      schedule.push({
        payment: i,
        paymentAmount: monthlyPayment,
        principal: principal,
        interest: interest,
        remainingBalance: remainingBalance,
      });

      if (remainingBalance === 0) break;
    }

    return schedule;
  };

  const handleLoanCalculation = (inputData) => {
    setLoanData(inputData);
    calculateLoanDetails(inputData);
    setCurrentStep(1);
  };

  const steps = [
    { label: t("Loan Input"), component: LoanInput },
    { label: t("Loan Results"), component: LoanResults },
    { label: t("Loan Comparison"), component: LoanComparison },
    { label: t("Financial Impact"), component: FinancialImpact },
    { label: t("Refinancing"), component: Refinancing },
    { label: t("Forgiveness Checker"), component: LoanForgivenessChecker },
    { label: t("Loan Options"), component: LoanOptions },
  ];

  const handleNext = () =>
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  const handleBack = () =>
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Container maxWidth="lg">
          <Helmet>
            <title>{t("Student Loan Calculator")}</title>
            <meta
              name="description"
              content={t(
                "Analyze, compare, and optimize your student loan options."
              )}
            />
          </Helmet>
          <Box my={4}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              align="center"
              color="primary"
            >
              {t("Student Loan Calculator")}
            </Typography>
            {!isMobile && (
              <Tabs
                value={currentStep}
                onChange={(_, newValue) => setCurrentStep(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="loan calculator steps"
              >
                {steps.map((step, index) => (
                  <Tab
                    key={index}
                    label={step.label}
                    disabled={index > currentStep}
                  />
                ))}
              </Tabs>
            )}
            <Paper elevation={3} sx={{ mt: 3, p: 3, borderRadius: 2 }}>
              <AnimatePresence mode="wait">
                {isCalculating ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="400px"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <motion.div
                    key={currentStep}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.3 }}
                  >
                    <CurrentStepComponent
                      onCalculate={handleLoanCalculation}
                      loanData={loanData}
                      calculatedResults={calculatedResults}
                      currentRates={currentRates}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </Paper>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                onClick={handleBack}
                disabled={currentStep === 0}
                variant="contained"
                color="secondary"
                sx={{ color: "text.primary" }}
              >
                {t("Back")}
              </Button>
              <Button
                onClick={handleNext}
                disabled={
                  currentStep === steps.length - 1 ||
                  (currentStep === 0 && !loanData)
                }
                variant="contained"
                color="primary"
              >
                {t("Next")}
              </Button>
            </Box>
          </Box>
        </Container>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default StudentLoanCalculator;
