import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Container,
  Box,
  Chip,
  LinearProgress,
  Button,
  useMediaQuery,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { blogPosts } from "./blogPosts";

let theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.2rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.7rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.4rem",
      fontWeight: 700,
    },
    body1: {
      fontSize: "1.1rem",
    },
    body2: {
      fontSize: "1rem",
    },
  },
});
theme = responsiveFontSizes(theme);

const BlogPostPage = () => {
  const { id } = useParams();
  const post = blogPosts.find((post) => post.id === parseInt(id));
  const [scrollProgress, setScrollProgress] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!post) {
    return <Typography>Post not found</Typography>;
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: post.title,
    description: post.excerpt,
    author: {
      "@type": "Person",
      name: post.author,
    },
    datePublished: post.date,
    image: "https://cashflowtime.com/blog-post-image.jpg", // Replace with actual image URL if available
    articleBody: post.content,
    publisher: {
      "@type": "Organization",
      name: "CashFlowTime",
      logo: {
        "@type": "ImageObject",
        url: "https://cashflowtime.com/logo.png", // Replace with actual logo URL
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{post.title} | CashFlowTime Blog</title>
        <meta name="description" content={post.excerpt} />
        <meta name="author" content={post.author} />
        <meta
          name="keywords"
          content={`${post.category}, financial advice, money management`}
        />
        <link rel="canonical" href={`https://cashflowtime.com/blog/${id}`} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <LinearProgress
        variant="determinate"
        value={scrollProgress}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          height: "6px",
          backgroundColor: "rgba(154, 205, 50, 0.2)",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "rgba(154, 205, 50, 0.8)",
          },
        }}
      />
      <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
        <Button
          component={RouterLink}
          to="/blog"
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 2 }}
        >
          Back to Blog
        </Button>
        <Typography variant="h1" component="h1" gutterBottom>
          {post.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isMobile ? "flex-start" : "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" color="text.secondary">
            {post.author} • {post.date}
          </Typography>
          <Chip
            label={post.category}
            size="small"
            sx={{ mt: isMobile ? 1 : 0 }}
          />
        </Box>
        <Typography variant="body1" sx={{ mb: 4, fontSize: "1.2rem" }}>
          {post.excerpt}
        </Typography>
        <Box
          dangerouslySetInnerHTML={{ __html: post.content }}
          sx={{
            mb: 4,
            "& h2": {
              fontSize: "1.8rem",
              fontWeight: "bold",
              mt: 4,
              mb: 2,
            },
            "& h3": {
              fontSize: "1.5rem",
              fontWeight: "bold",
              mt: 3,
              mb: 2,
            },
            "& p": {
              fontSize: "1.1rem",
              lineHeight: 1.6,
              mb: 2,
            },
            "& ul, & ol": {
              fontSize: "1.1rem",
              lineHeight: 1.6,
              mb: 2,
              pl: 4,
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            component={RouterLink}
            to="/blog"
            startIcon={<ArrowBackIcon />}
            variant="contained"
            color="primary"
          >
            Back to Blog
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default BlogPostPage;
