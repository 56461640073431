import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./component/NavBar.js";
import Footer from "./component/Footer.js";
import LandingPage from "./component/LandingPage.js";
// import AdvancedCalculator from "./component/AdvanceLoanCalculator/AdvancedCalculator.js";
import StudentLoanCalculator from "./component/StudentLoanCalculator/StudentLoanCalculator.js";
import BlogPage from "./component/blog/BlogPage.js";
import BlogPostPage from "./component/blog/BlogPostPage.js";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/advanced-calculator"
            element={<StudentLoanCalculator />}
          />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogPostPage />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
