import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

// Hotjar integration component
const HotjarIntegration = () => {
  useEffect(() => {
    // Hotjar Tracking Code
    const hotjarScript = document.createElement("script");
    hotjarScript.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5043480,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(hotjarScript);

    return () => {
      // Cleanup if needed
      document.head.removeChild(hotjarScript);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return null; // This component doesn't render anything
};

// Wrap your App component with HotjarIntegration
const AppWithHotjar = () => (
  <React.StrictMode>
    <HotjarIntegration />
    <Analytics />
    <SpeedInsights />
    <App />
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppWithHotjar />);

reportWebVitals();
