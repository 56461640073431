import React from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";

const LoanResults = ({ loanData, calculatedResults }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!calculatedResults) {
    return (
      <Typography>
        {t("No results to display. Please calculate first.")}
      </Typography>
    );
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat(
      loanData.country === "USA" ? "en-US" : "en-GB",
      {
        style: "currency",
        currency: loanData.country === "USA" ? "USD" : "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    ).format(amount);
  };

  const debtFreeDate = new Date();
  debtFreeDate.setMonth(
    debtFreeDate.getMonth() + calculatedResults.amortizationSchedule.length
  );

  const pieChartData = [
    {
      name: t("Principal"),
      value: Number(loanData.loanAmount),
      color: "#0088FE",
    },
    {
      name: t("Total Interest"),
      value: calculatedResults.totalInterest,
      color: "#00C49F",
    },
    {
      name: t("Interest Accrued"),
      value: calculatedResults.accruedInterest,
      color: "#FFBB28",
    },
  ];

  const barChartData = [
    { name: t("Monthly Payment"), amount: calculatedResults.monthlyPayment },
    { name: t("Total Repayment"), amount: calculatedResults.totalRepayment },
    { name: t("Total Interest"), amount: calculatedResults.totalInterest },
  ];

  const lineChartData = calculatedResults.amortizationSchedule
    .filter((_, index) => index % 12 === 0)
    .map((data, index) => ({
      year: index + 1,
      balance: data.remainingBalance,
    }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper elevation={3} sx={{ p: 1 }}>
          <Typography variant="body2">{`${label}: ${formatCurrency(
            payload[0].value
          )}`}</Typography>
        </Paper>
      );
    }
    return null;
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {t("Loan Analysis Results")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              bgcolor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Loan Summary")}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>{t("Loan Amount")}</TableCell>
                    <TableCell align="right">
                      {formatCurrency(loanData.loanAmount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Interest Rate")}</TableCell>
                    <TableCell align="right">
                      {loanData.interestRate}%
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Loan Term")}</TableCell>
                    <TableCell align="right">
                      {loanData.loanTerm} {t("years")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Monthly Payment")}</TableCell>
                    <TableCell align="right">
                      {formatCurrency(calculatedResults.monthlyPayment)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Total Interest")}</TableCell>
                    <TableCell align="right">
                      {formatCurrency(calculatedResults.totalInterest)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Total Repayment")}</TableCell>
                    <TableCell align="right">
                      {formatCurrency(calculatedResults.totalRepayment)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Debt-Free Date")}</TableCell>
                    <TableCell align="right">
                      {debtFreeDate.toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {t("Interest Accrued")}
                        <Tooltip
                          title={t(
                            "It is the amount of interest accrued on principle after grace period ends till installment payment towards loan start."
                          )}
                        >
                          <IconButton size="small">
                            <Info fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(calculatedResults.accruedInterest)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Interest Accrual Period")}</TableCell>
                    <TableCell align="right">
                      {calculatedResults.accruedInterestPeriod.toFixed(2)}{" "}
                      {t("years")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              bgcolor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Loan Breakdown")}
            </Typography>
            <Box height={isMobile ? 200 : 300}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ name, percent }) =>
                      `${name}: ${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <RechartsTooltip content={<CustomTooltip />} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: theme.palette.background.paper }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Key Metrics")}
            </Typography>
            <Box height={isMobile ? 200 : 300}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={barChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <RechartsTooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="amount" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: theme.palette.background.paper }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Loan Balance Over Time")}
            </Typography>
            <Box height={isMobile ? 200 : 300}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={lineChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <RechartsTooltip content={<CustomTooltip />} />
                  <Legend />
                  <Line type="monotone" dataKey="balance" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: theme.palette.background.paper }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Amortization Schedule")}
            </Typography>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Payment")}</TableCell>
                    <TableCell align="right">{t("Payment Amount")}</TableCell>
                    <TableCell align="right">{t("Principal")}</TableCell>
                    <TableCell align="right">{t("Interest")}</TableCell>
                    <TableCell align="right">
                      {t("Remaining Balance")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculatedResults.amortizationSchedule.map((row) => (
                    <TableRow key={row.payment}>
                      <TableCell>{row.payment}</TableCell>
                      <TableCell align="right">
                        {formatCurrency(row.paymentAmount)}
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(row.principal)}
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(row.interest)}
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(row.remainingBalance)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoanResults;
