import React, { useState, useCallback } from "react";
import { Typography, Box, Modal, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

const features = [
  {
    id: 1,
    title: "Student Loan Calculator",
    src: "/productShowcase/loanResult.png",
    alt: "Student Loan Calculator interface",
  },
  {
    id: 2,
    title: "Loan Comparison",
    src: "/productShowcase/loanComparision.png",
    alt: "Loan Comparison tool",
  },
  {
    id: 3,
    title: "Financial Impact",
    src: "/productShowcase/financialImpact.png",
    alt: "Financial Impact analysis",
  },
  {
    id: 4,
    title: "Refinancing",
    src: "/productShowcase/loanRefinance.png",
    alt: "Refinancing options",
  },
  {
    id: 5,
    title: "Forgiveness Checker",
    src: "/productShowcase/loanForgivenes.png",
    alt: "Loan Forgiveness Checker",
  },
];

const FeatureText = styled(Typography)(({ theme, isActive }) => ({
  fontSize: "0.9rem",
  fontWeight: isActive ? 600 : 400,
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  cursor: "pointer",
  transition: "all 0.3s",
  "&:hover": {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
}));

const SeparatorSvg = ({ size = 24 }) => (
  <Box
    component="span"
    sx={{
      mx: 1,
      display: "inline-flex",
      alignItems: "center",
      color: "text.secondary",
    }}
  >
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="8"
        y1="24"
        x2="24"
        y2="8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </Box>
);

const ProductShowcase = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [zoomOpen, setZoomOpen] = useState(false);

  const handleFeatureClick = useCallback((index) => {
    setActiveIndex(index);
  }, []);

  const handleZoomOpen = () => setZoomOpen(true);
  const handleZoomClose = () => setZoomOpen(false);

  return (
    <Box
      sx={{
        py: { xs: 4, sm: 6, md: 8 },
        backgroundColor: "rgb(247, 255, 247)",
      }}
    >
      <Box sx={{ maxWidth: 1200, mx: "auto", px: 2 }}>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          fontWeight="bold"
        >
          Discover Our Powerful Features
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mb: 4,
          }}
        >
          {features.map((feature, index) => (
            <React.Fragment key={feature.id}>
              <FeatureText
                isActive={index === activeIndex}
                onClick={() => handleFeatureClick(index)}
              >
                {feature.title}
              </FeatureText>
              {index < features.length - 1 && <SeparatorSvg size={24} />}
            </React.Fragment>
          ))}
        </Box>
        <Box sx={{ maxWidth: 800, mx: "auto", mb: 4, position: "relative" }}>
          <motion.img
            key={activeIndex}
            src={features[activeIndex].src}
            alt={features[activeIndex].alt}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            }}
          />
          <IconButton
            onClick={handleZoomOpen}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
            }}
          >
            <ZoomInIcon />
          </IconButton>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              backgroundColor: "black",
              color: "white",
              border: "2px solid #bdff8a",
              padding: "12px 24px",
              fontSize: "1rem",
              fontWeight: "bold",
              borderRadius: "28px",
              cursor: "pointer",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            Try for free
          </motion.button>
        </Box>
      </Box>
      <Modal open={zoomOpen} onClose={handleZoomClose} closeAfterTransition>
        <AnimatePresence>
          {zoomOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90vw",
                height: "90vh",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                outline: "none",
              }}
            >
              <img
                src={features[activeIndex].src}
                alt={features[activeIndex].alt}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
              <IconButton
                onClick={handleZoomClose}
                sx={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                }}
              >
                <CloseIcon />
              </IconButton>
            </motion.div>
          )}
        </AnimatePresence>
      </Modal>
    </Box>
  );
};

export default ProductShowcase;
