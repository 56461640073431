import React, { useState, useCallback, useMemo } from "react";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const LoanInput = ({ onCalculate, currentRates }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRepaymentPlan, setSelectedRepaymentPlan] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const validationSchema = Yup.object().shape({
    country: Yup.string().required(t("Country is required")),
    loanAmount: Yup.number().positive().required(t("Loan amount is required")),
    loanType: Yup.string().required(t("Loan type is required")),
    interestRate: Yup.number()
      .min(0)
      .max(100)
      .required(t("Interest rate is required")),
    loanTerm: Yup.number()
      .positive()
      .integer()
      .required(t("Loan term is required")),
    repaymentPlan: Yup.string().required(t("Repayment plan is required")),
    gracePeriod: Yup.number().min(0).integer(),
    expectedSalary: Yup.number().positive(),
    startPaymentsImmediately: Yup.boolean().required(),
    yearsBetweenLoanAndGraduation: Yup.number().when(
      "startPaymentsImmediately",
      {
        is: false,
        then: (schema) =>
          schema
            .required(
              t("Years between loan issuance and graduation is required")
            )
            .min(0, t("Years must be non-negative"))
            .max(10, t("Years must be 10 or less")),
        otherwise: (schema) => schema.nullable(),
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      country: "",
      loanAmount: "",
      loanType: "",
      interestRate: "",
      loanTerm: "",
      repaymentPlan: "",
      gracePeriod: "",
      expectedSalary: "",
      startPaymentsImmediately: true,
      yearsBetweenLoanAndGraduation: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const accruedInterestPeriod = Math.max(
        0,
        values.yearsBetweenLoanAndGraduation - values.gracePeriod / 12
      );
      const accruedInterest =
        values.loanAmount * (values.interestRate / 100) * accruedInterestPeriod;
      const totalLoanAmount = Number(values.loanAmount) + accruedInterest;

      onCalculate({
        ...values,
        accruedInterest,
        totalLoanAmount,
        accruedInterestPeriod,
      });
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.handleChange(event);
    formik.setFieldTouched(name, true, false);

    if (name === "loanType" || name === "country") {
      const newInterestRate = getInterestRate(
        value,
        name === "country" ? value : formik.values.country
      );
      formik.setFieldValue("interestRate", newInterestRate);
    }

    if (name === "startPaymentsImmediately") {
      formik.setFieldValue(name, value === "true");
      if (value === "true") {
        formik.setFieldValue("yearsBetweenLoanAndGraduation", "");
      }
    }
  };

  const getInterestRate = useCallback(
    (loanType, country) => {
      if (country === "USA") {
        if (
          loanType === "federal_subsidized" ||
          loanType === "federal_unsubsidized"
        ) {
          return currentRates.USA.federal.subsidized;
        } else if (loanType === "federal_unsubsidized_graduate") {
          return currentRates.USA.federal.unsubsidized.graduate;
        } else if (loanType === "federal_plus") {
          return currentRates.USA.federal.plus;
        } else if (loanType === "private") {
          return currentRates.USA.private.average;
        }
      } else if (country === "UK") {
        return currentRates.UK[loanType];
      }
      return "";
    },
    [currentRates]
  );

  const getGracePeriod = useCallback((loanType, country) => {
    if (country === "USA") {
      if (
        [
          "federal_subsidized",
          "federal_unsubsidized",
          "federal_unsubsidized_graduate",
          "federal_plus",
        ].includes(loanType)
      ) {
        return 6;
      } else if (loanType === "private") {
        return 0;
      }
    } else if (country === "UK") {
      return 0;
    }
    return 0;
  }, []);

  const loanTypeOptions = useMemo(() => {
    if (formik.values.country === "USA") {
      return [
        { value: "federal_subsidized", label: t("Federal Subsidized") },
        {
          value: "federal_unsubsidized",
          label: t("Federal Unsubsidized (Undergraduate)"),
        },
        {
          value: "federal_unsubsidized_graduate",
          label: t("Federal Unsubsidized (Graduate)"),
        },
        { value: "federal_plus", label: t("Federal PLUS") },
        { value: "private", label: t("Private") },
      ];
    } else if (formik.values.country === "UK") {
      return [
        { value: "plan1", label: t("Plan 1") },
        { value: "plan2", label: t("Plan 2") },
        { value: "postgraduate", label: t("Postgraduate Loan") },
      ];
    }
    return [];
  }, [formik.values.country, t]);

  const repaymentPlanOptions = useMemo(() => {
    if (formik.values.country === "USA") {
      if (formik.values.loanType === "private") {
        return [
          { value: "standard", label: t("Standard Repayment") },
          { value: "interest_only", label: t("Interest-Only") },
          { value: "deferred", label: t("Deferred") },
        ];
      } else {
        return [
          { value: "standard", label: t("Standard Repayment") },
          { value: "graduated", label: t("Graduated") },
          { value: "extended", label: t("Extended") },
          { value: "income_driven", label: t("Income-Driven") },
        ];
      }
    } else if (formik.values.country === "UK") {
      return [{ value: "income_contingent", label: t("Income Contingent") }];
    }
    return [];
  }, [formik.values.country, formik.values.loanType, t]);

  const getRepaymentPlanTooltip = useCallback(
    (plan) => {
      const tooltips = {
        standard: t(
          "Fixed monthly payments over a 10-year term. Typically results in paying less interest overall."
        ),
        graduated: t(
          "Payments start low and increase every two years. 10-year term. Good if you expect your income to increase steadily."
        ),
        extended: t(
          "Lower monthly payments over a longer term (up to 25 years). May result in paying more interest overall."
        ),
        income_driven: t(
          "Payments are based on your income and family size. May result in loan forgiveness after 20-25 years."
        ),
        interest_only: t(
          "Pay only the interest for a set period, then start paying principal and interest. May result in higher overall costs."
        ),
        deferred: t(
          "No payments while in school, but interest accrues. Payments start after graduation. May result in higher overall costs."
        ),
        income_contingent: t(
          "You pay 9% of your income above a certain threshold. Debt is cancelled after 30 years (Plan 2) or when you turn 65 (Plan 1)."
        ),
      };
      return tooltips[plan] || "";
    },
    [t]
  );

  const handleRepaymentPlanChange = (event) => {
    const plan = event.target.value;
    setSelectedRepaymentPlan(plan);
    formik.setFieldValue("repaymentPlan", plan);
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showErrorSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const renderTooltip = (text) => (
    <Tooltip title={text}>
      <IconButton size="small">
        <Info fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Typography variant="h5" gutterBottom>
        {t("Enter Your Loan Details")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={formik.touched.country && Boolean(formik.errors.country)}
            >
              <InputLabel>{t("Country")}</InputLabel>
              <Select
                name="country"
                value={formik.values.country}
                onChange={handleChange}
              >
                <MenuItem value="USA">{t("United States")}</MenuItem>
                <MenuItem value="UK">{t("United Kingdom")}</MenuItem>
              </Select>
              <FormHelperText>
                {formik.touched.country && formik.errors.country}
              </FormHelperText>
            </FormControl>
          </Grid>
          {formik.values.country && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="loanAmount"
                  label={t("Loan Amount")}
                  value={formik.values.loanAmount}
                  onChange={handleChange}
                  error={
                    formik.touched.loanAmount &&
                    Boolean(formik.errors.loanAmount)
                  }
                  helperText={
                    formik.touched.loanAmount && formik.errors.loanAmount
                  }
                  InputProps={{
                    endAdornment: renderTooltip(
                      t(
                        "The total amount you need to borrow for your education"
                      )
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.loanType && Boolean(formik.errors.loanType)
                  }
                >
                  <InputLabel>{t("Loan Type")}</InputLabel>
                  <Select
                    name="loanType"
                    value={formik.values.loanType}
                    onChange={handleChange}
                  >
                    {loanTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.loanType && formik.errors.loanType}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="interestRate"
                  label={t("Interest Rate (%)")}
                  value={formik.values.interestRate}
                  onChange={handleChange}
                  error={
                    formik.touched.interestRate &&
                    Boolean(formik.errors.interestRate)
                  }
                  helperText={
                    formik.touched.interestRate && formik.errors.interestRate
                  }
                  InputProps={{
                    endAdornment: renderTooltip(
                      t("Annual interest rate for your loan")
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  error={
                    formik.touched.repaymentPlan &&
                    Boolean(formik.errors.repaymentPlan)
                  }
                >
                  <Typography variant="subtitle1">
                    {t("Repayment Plan")}
                  </Typography>
                  <RadioGroup
                    name="repaymentPlan"
                    value={formik.values.repaymentPlan}
                    onChange={handleRepaymentPlanChange}
                  >
                    {repaymentPlanOptions.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        onClick={handleOpenDialog}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText>
                    {formik.touched.repaymentPlan &&
                      formik.errors.repaymentPlan}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="loanTerm"
                  label={t("Loan Term (years)")}
                  value={formik.values.loanTerm}
                  onChange={handleChange}
                  error={
                    formik.touched.loanTerm && Boolean(formik.errors.loanTerm)
                  }
                  helperText={formik.touched.loanTerm && formik.errors.loanTerm}
                  InputProps={{
                    endAdornment: renderTooltip(
                      t("The duration of your loan in years")
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="gracePeriod"
                  label={t("Grace Period (months)")}
                  value={formik.values.gracePeriod}
                  onChange={handleChange}
                  error={
                    formik.touched.gracePeriod &&
                    Boolean(formik.errors.gracePeriod)
                  }
                  helperText={
                    formik.touched.gracePeriod && formik.errors.gracePeriod
                  }
                  InputProps={{
                    endAdornment: renderTooltip(
                      t("Period after graduation before repayment begins")
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="expectedSalary"
                  label={t("Expected Annual Salary")}
                  value={formik.values.expectedSalary}
                  onChange={handleChange}
                  error={
                    formik.touched.expectedSalary &&
                    Boolean(formik.errors.expectedSalary)
                  }
                  helperText={
                    formik.touched.expectedSalary &&
                    formik.errors.expectedSalary
                  }
                  InputProps={{
                    endAdornment: renderTooltip(
                      t("Your expected annual salary after graduation")
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography variant="subtitle1">
                    {t(
                      "Start installment payments immediately or after graduation?"
                    )}
                  </Typography>
                  <RadioGroup
                    name="startPaymentsImmediately"
                    value={formik.values.startPaymentsImmediately.toString()}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("Immediately")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("After graduation")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {!formik.values.startPaymentsImmediately && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="yearsBetweenLoanAndGraduation"
                    label={t(
                      "Approximate years between loan issuance and graduation"
                    )}
                    value={formik.values.yearsBetweenLoanAndGraduation}
                    onChange={handleChange}
                    error={
                      formik.touched.yearsBetweenLoanAndGraduation &&
                      Boolean(formik.errors.yearsBetweenLoanAndGraduation)
                    }
                    helperText={
                      formik.touched.yearsBetweenLoanAndGraduation &&
                      formik.errors.yearsBetweenLoanAndGraduation
                    }
                    InputProps={{
                      endAdornment: renderTooltip(
                        t(
                          "Estimate the number of years from when you receive the loan to your expected graduation"
                        )
                      ),
                    }}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {t("Calculate")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{t("Repayment Plan Details")}</DialogTitle>
        <DialogContent>
          <Typography>
            {getRepaymentPlanTooltip(selectedRepaymentPlan)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default LoanInput;
