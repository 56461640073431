import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import NotificationBar from "./NotificationBar"; // Import the NotificationBar component

const CashFlowTimeLogo = ({ className }) => (
  <Typography
    variant="h6"
    component="div"
    className={className}
    sx={{
      fontWeight: 700,
      color: "black",
      display: "inline-block",
      "& .highlight": {
        color: "#4A54EB",
      },
    }}
  >
    <span className="highlight">Cash</span>
    Flow
    <span className="highlight">Time</span>
  </Typography>
);

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleOpenFeedback = () => {
    setFeedbackOpen(true);
  };

  const handleCloseFeedback = () => {
    setFeedbackOpen(false);
  };

  return (
    <>
      <NotificationBar onOpenFeedback={handleOpenFeedback} />
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Toolbar>
          <Link to="/" style={{ textDecoration: "none", flexGrow: 1 }}>
            <CashFlowTimeLogo />
          </Link>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <>
              {/*
              <Button
                color="inherit"
                component={Link}
                to="/about"
                sx={{ mx: 1, color: "black" }}
              >
                About Us
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/advanced-calculator"
                sx={{ mx: 1, color: "black" }}
              >
                Advanced Loan Calculator
              </Button>
              <Button
                color="inherit"
                onClick={handleClick}
                sx={{ mx: 1, color: "black" }}
              >
                Free Tools
              </Button>
             */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                {/* <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/budget-calculator"
                >
                  Budget Calculator
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/loan-fun-facts"
                >
                  Loan Fun Facts
                </MenuItem> */}
              </Menu>
              {/* <Button
                color="inherit"
                component={Link}
                to="/login"
                sx={{ mx: 1, color: "black" }}
              >
                Login
              </Button> */}
              <Button
                variant="contained"
                component={Link}
                to="/blog"
                sx={{
                  ml: 2,
                  borderRadius: "50px",
                  textTransform: "none",
                  fontWeight: 600,
                  bgcolor: "#4A54EB",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#3A43DA",
                  },
                }}
              >
                Blogs
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={isMobile && mobileMenuOpen}
        onClose={toggleMobileMenu}
      >
        <Box
          sx={{
            width: 250,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          role="presentation"
          onClick={toggleMobileMenu}
          onKeyDown={toggleMobileMenu}
        >
          <List>
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CashFlowTimeLogo />
                <IconButton onClick={toggleMobileMenu}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </ListItem>
            {[
              { text: "About Us", link: "/about" },
              {
                text: "Advanced Loan Calculator",
                link: "/advanced-calculator",
              },
              { text: "Budget Calculator", link: "/budget-calculator" },
              { text: "Loan Fun Facts", link: "/loan-fun-facts" },
              { text: "Login", link: "/login" },
            ].map((item, index) => (
              <ListItem
                button
                component={Link}
                to={item.link}
                key={index}
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  "&:last-child": { borderBottom: "none" },
                }}
              >
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: "bold",
                      color: "black",
                      textShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                      "&:hover": { color: "#4A54EB" },
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
          <Box sx={{ p: 2 }}>
            <Button
              fullWidth
              variant="contained"
              component={Link}
              to="/blog"
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                fontWeight: 600,
                bgcolor: "#4A54EB",
                color: "white",
                "&:hover": {
                  bgcolor: "#3A43DA",
                },
              }}
            >
              Blogs
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default NavBar;
