import React from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import { ExpandMore, CheckCircle, Cancel, Info } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const LoanOptions = ({ loanData }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const loanOptions = {
    USA: {
      federal_subsidized: {
        pros: [
          t("Government pays interest during school and grace period"),
          t("Generally lower interest rates"),
          t("Access to income-driven repayment plans"),
        ],
        cons: [
          t("Limited loan amounts"),
          t("Must demonstrate financial need"),
          t("Only available for undergraduate students"),
        ],
        considerations: [
          t("Annual and aggregate loan limits apply"),
          t("Must be enrolled at least half-time"),
        ],
      },
      federal_unsubsidized: {
        pros: [
          t("Available to undergraduate and graduate students"),
          t("No requirement to demonstrate financial need"),
          t("Access to income-driven repayment plans"),
        ],
        cons: [
          t("Interest accrues during all periods"),
          t("Higher interest rates than subsidized loans"),
        ],
        considerations: [
          t("Annual and aggregate loan limits apply"),
          t("Must be enrolled at least half-time"),
        ],
      },
      federal_plus: {
        pros: [
          t("Can borrow up to the full cost of attendance"),
          t("Fixed interest rate"),
          t("Access to income-driven repayment plans (for grad PLUS)"),
        ],
        cons: [
          t("Higher interest rates than other federal loans"),
          t("Origination fee applies"),
          t("Credit check required"),
        ],
        considerations: [
          t("Parents or graduate/professional students only"),
          t("Cannot have adverse credit history"),
        ],
      },
      private: {
        pros: [
          t("Can often borrow larger amounts"),
          t("May offer lower rates for excellent credit"),
          t("Possible to refinance for better terms"),
        ],
        cons: [
          t("Generally higher interest rates"),
          t("May require a cosigner"),
          t("No access to federal repayment plans or forgiveness programs"),
        ],
        considerations: [
          t("Terms vary by lender"),
          t("Credit score heavily influences rates and terms"),
        ],
      },
    },
    UK: {
      plan1: {
        pros: [t("Lower repayment threshold"), t("Lower interest rates")],
        cons: [t("Repayments start at a lower income level")],
        considerations: [
          t("For students who started before September 1, 2012"),
          t("Repayment is 9% of income above the threshold"),
        ],
      },
      plan2: {
        pros: [
          t("Higher repayment threshold than Plan 1"),
          t("Debt written off after 30 years"),
        ],
        cons: [t("Higher interest rates than Plan 1")],
        considerations: [
          t("For students who started on or after September 1, 2012"),
          t("Repayment is 9% of income above the threshold"),
        ],
      },
      postgraduate: {
        pros: [
          t("Available for master's and doctoral degrees"),
          t("Not means-tested"),
        ],
        cons: [
          t("Separate loan from undergraduate loans"),
          t("Interest accrues from day one"),
        ],
        considerations: [
          t("Maximum loan amount applies"),
          t("Repayment is 6% of income above the threshold"),
        ],
      },
    },
  };

  const renderList = (items, icon) => (
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );

  const selectedLoanOption = loanOptions[loanData.country]?.[loanData.loanType];

  if (!selectedLoanOption) {
    return (
      <Box>
        <Typography variant="h5" gutterBottom>
          {t("Loan Option Details")}
        </Typography>
        <Paper
          elevation={3}
          sx={{ p: 2, bgcolor: theme.palette.secondary.main }}
        >
          <Typography>
            {t(
              "Loan option details are not available for the selected country and loan type."
            )}
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {t("Loan Option Details")}
      </Typography>
      <Paper elevation={3} sx={{ p: 2, bgcolor: theme.palette.secondary.main }}>
        <Typography variant="h6" gutterBottom>
          {t(
            loanData.loanType.replace("_", " ").charAt(0).toUpperCase() +
              loanData.loanType.replace("_", " ").slice(1)
          )}
        </Typography>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{t("Pros")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderList(
              selectedLoanOption.pros,
              <CheckCircle color="success" />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{t("Cons")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderList(selectedLoanOption.cons, <Cancel color="error" />)}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{t("Important Considerations")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderList(
              selectedLoanOption.considerations,
              <Info color="primary" />
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

export default LoanOptions;
