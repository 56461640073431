import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Container,
  Box,
  Divider,
  Chip,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { blogPosts } from "./blogPosts";

let theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.3rem",
      fontWeight: 700,
    },
  },
});
theme = responsiveFontSizes(theme);

const BlogPost = ({ title, excerpt, author, date, link, category }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          transition: "background-color 0.3s",
        },
        cursor: "pointer",
      }}
      component={RouterLink}
      to={link}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h3" component="h2" gutterBottom fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {author} • {date}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {excerpt}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Chip label={category} size="small" />
      </Box>
    </Box>
  );
};

const BlogPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const featuredPosts = blogPosts.filter((post) => post.featured);
  const regularPosts = blogPosts.filter((post) => !post.featured);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Blog",
    name: "CashFlowTime Blog",
    description:
      "Expert advice on student loans, financial planning, and more.",
    url: "https://cashflowtime.com/blog",
    blogPost: blogPosts.map((post) => ({
      "@type": "BlogPosting",
      headline: post.title,
      description: post.excerpt,
      author: {
        "@type": "Person",
        name: post.author,
      },
      datePublished: post.date,
    })),
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>CashFlowTime Blog - Expert Financial Advice</title>
        <meta
          name="description"
          content="Expert advice on student loans, financial planning, and more. Stay informed with the latest tips and strategies for managing your finances."
        />
        <meta
          name="keywords"
          content="student loans, financial planning, personal finance, money management, debt reduction"
        />
        <link rel="canonical" href="https://cashflowtime.com/blog" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <LinearProgress
        variant="determinate"
        value={scrollProgress}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          height: "6px",
          backgroundColor: "rgba(154, 205, 50, 0.2)",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "rgba(154, 205, 50, 0.8)",
          },
        }}
      />
      <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
        <Typography variant="h1" component="h1" gutterBottom sx={{ mb: 4 }}>
          CashFlowTime Blog
        </Typography>

        {featuredPosts.length > 0 && (
          <>
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Featured Posts
            </Typography>
            <Divider sx={{ mb: 4 }} />
            <Box sx={{ mb: 6 }}>
              {featuredPosts.map((post, index) => (
                <Box
                  key={post.id}
                  sx={{ mb: index < featuredPosts.length - 1 ? 5 : 0 }}
                >
                  <BlogPost
                    title={post.title}
                    excerpt={post.excerpt}
                    author={post.author}
                    date={post.date}
                    link={`/blog/${post.id}`}
                    category={post.category}
                  />
                </Box>
              ))}
            </Box>
          </>
        )}

        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          All Posts
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <Box>
          {regularPosts.map((post, index) => (
            <Box
              key={post.id}
              sx={{ mb: index < regularPosts.length - 1 ? 5 : 0 }}
            >
              <BlogPost
                title={post.title}
                excerpt={post.excerpt}
                author={post.author}
                date={post.date}
                link={`/blog/${post.id}`}
                category={post.category}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default BlogPage;
