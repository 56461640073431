import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Paper,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  useTheme,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const LoanForgivenessChecker = ({ loanData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState({
    occupation: "",
    employerType: "",
    yearsOfService: "",
    incomeBasedRepayment: "",
    publicServiceCommitment: "",
    teachingCommitment: "",
    militaryService: "",
    disability: "",
  });
  const [eligibilityResult, setEligibilityResult] = useState(null);

  useEffect(() => {
    // Reset answers when loan data changes
    setAnswers({
      occupation: "",
      employerType: "",
      yearsOfService: "",
      incomeBasedRepayment: "",
      publicServiceCommitment: "",
      teachingCommitment: "",
      militaryService: "",
      disability: "",
    });
    setActiveStep(0);
    setEligibilityResult(null);
  }, [loanData]);

  const handleChange = (event) => {
    setAnswers({ ...answers, [event.target.name]: event.target.value });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkEligibility = () => {
    let result = [];

    if (loanData.country === "USA") {
      if (loanData.loanType.includes("federal")) {
        // Public Service Loan Forgiveness (PSLF)
        if (
          answers.employerType === "public" &&
          answers.yearsOfService === "10+" &&
          answers.publicServiceCommitment === "yes"
        ) {
          result.push(
            t(
              "You may be eligible for Public Service Loan Forgiveness (PSLF). After making 120 qualifying monthly payments while working full-time for a qualifying employer, your remaining balance may be forgiven."
            )
          );
        }

        // Income-Driven Repayment (IDR) Forgiveness
        if (answers.incomeBasedRepayment === "yes") {
          result.push(
            t(
              "You may be eligible for Income-Driven Repayment (IDR) Forgiveness after 20-25 years of qualifying payments, depending on your specific plan."
            )
          );
        }

        // Teacher Loan Forgiveness
        if (
          answers.occupation === "teacher" &&
          answers.yearsOfService === "5+" &&
          answers.teachingCommitment === "yes"
        ) {
          result.push(
            t(
              "You may be eligible for Teacher Loan Forgiveness. After teaching full-time for five complete and consecutive academic years in a low-income school or educational service agency, you may be eligible for forgiveness of up to $17,500 on your Direct Subsidized and Unsubsidized Loans and your Subsidized and Unsubsidized Federal Stafford Loans."
            )
          );
        }

        // Military Service Loan Forgiveness
        if (answers.militaryService === "yes") {
          result.push(
            t(
              "As a member of the military, you may be eligible for specific loan forgiveness programs or repayment assistance. Contact your loan servicer or the Department of Defense for more information on military-specific benefits."
            )
          );
        }

        // Total and Permanent Disability Discharge
        if (answers.disability === "yes") {
          result.push(
            t(
              "If you have a total and permanent disability, you may be eligible for a Total and Permanent Disability (TPD) discharge, which would forgive your federal student loans."
            )
          );
        }

        if (result.length === 0) {
          result.push(
            t(
              "Based on your answers, you may not be immediately eligible for loan forgiveness programs. However, you may still benefit from income-driven repayment plans or other federal loan benefits. Consider consulting with a student loan advisor for personalized advice."
            )
          );
        }
      } else {
        result.push(
          t(
            "Private student loans are not eligible for federal loan forgiveness programs. However, you may want to explore refinancing options or discuss repayment alternatives with your lender."
          )
        );
      }
    } else if (loanData.country === "UK") {
      if (loanData.loanType === "plan1") {
        result.push(
          t(
            "Your Plan 1 student loan will be automatically written off when you turn 65, regardless of the amount repaid."
          )
        );
      } else if (loanData.loanType === "plan2") {
        result.push(
          t(
            "Your Plan 2 student loan will be automatically written off 30 years after you become eligible to repay, regardless of the amount repaid."
          )
        );
      } else if (loanData.loanType === "postgraduate") {
        result.push(
          t(
            "Your Postgraduate Loan will be automatically written off 30 years after you become eligible to repay, regardless of the amount repaid."
          )
        );
      }

      result.push(
        t(
          "While this is not technically loan forgiveness, it does mean you won't have to repay any remaining balance after this point."
        )
      );
    } else {
      result.push(
        t(
          "Loan forgiveness options may vary for your country. Please consult with your loan provider or a local financial advisor for more information on available programs."
        )
      );
    }

    setEligibilityResult(result);
    handleNext(); // Advance to the next step to show results
  };

  const steps = [
    {
      label: t("Occupation"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="occupation"
            value={answers.occupation}
            onChange={handleChange}
          >
            <FormControlLabel
              value="teacher"
              control={<Radio />}
              label={t("Teacher")}
            />
            <FormControlLabel
              value="nurse"
              control={<Radio />}
              label={t("Nurse")}
            />
            <FormControlLabel
              value="government"
              control={<Radio />}
              label={t("Government Employee")}
            />
            <FormControlLabel
              value="nonprofit"
              control={<Radio />}
              label={t("Non-Profit Employee")}
            />
            <FormControlLabel
              value="military"
              control={<Radio />}
              label={t("Military")}
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label={t("Other")}
            />
          </RadioGroup>
        </FormControl>
      ),
    },
    {
      label: t("Employer Type"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="employerType"
            value={answers.employerType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="public"
              control={<Radio />}
              label={t("Public Service / Government")}
            />
            <FormControlLabel
              value="nonprofit"
              control={<Radio />}
              label={t("Non-Profit")}
            />
            <FormControlLabel
              value="private"
              control={<Radio />}
              label={t("Private Sector")}
            />
          </RadioGroup>
        </FormControl>
      ),
    },
    {
      label: t("Years of Service"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="yearsOfService"
            value={answers.yearsOfService}
            onChange={handleChange}
          >
            <FormControlLabel
              value="0-5"
              control={<Radio />}
              label={t("0-5 years")}
            />
            <FormControlLabel
              value="5-10"
              control={<Radio />}
              label={t("5-10 years")}
            />
            <FormControlLabel
              value="10+"
              control={<Radio />}
              label={t("10+ years")}
            />
          </RadioGroup>
        </FormControl>
      ),
    },
    {
      label: t("Income-Based Repayment"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="incomeBasedRepayment"
            value={answers.incomeBasedRepayment}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={t("Yes")}
            />
            <FormControlLabel value="no" control={<Radio />} label={t("No")} />
          </RadioGroup>
        </FormControl>
      ),
    },
    {
      label: t("Public Service Commitment"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="publicServiceCommitment"
            value={answers.publicServiceCommitment}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={t("Yes, I am committed to public service for 10+ years")}
            />
            <FormControlLabel value="no" control={<Radio />} label={t("No")} />
          </RadioGroup>
        </FormControl>
      ),
    },
    {
      label: t("Teaching Commitment"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="teachingCommitment"
            value={answers.teachingCommitment}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={t(
                "Yes, I am teaching in a low-income school or educational service agency"
              )}
            />
            <FormControlLabel value="no" control={<Radio />} label={t("No")} />
          </RadioGroup>
        </FormControl>
      ),
    },
    {
      label: t("Military Service"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="militaryService"
            value={answers.militaryService}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={t("Yes, I am serving or have served in the military")}
            />
            <FormControlLabel value="no" control={<Radio />} label={t("No")} />
          </RadioGroup>
        </FormControl>
      ),
    },
    {
      label: t("Disability Status"),
      content: (
        <FormControl component="fieldset">
          <RadioGroup
            name="disability"
            value={answers.disability}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={t("Yes, I have a total and permanent disability")}
            />
            <FormControlLabel value="no" control={<Radio />} label={t("No")} />
          </RadioGroup>
        </FormControl>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {t("Loan Forgiveness Eligibility Checker")}
      </Typography>
      <Paper
        elevation={3}
        sx={{ p: 2, bgcolor: theme.palette.background.paper }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">{t("Last step")}</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Box>
                  {step.content}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={
                          index === steps.length - 1
                            ? checkEligibility
                            : handleNext
                        }
                        sx={{
                          mt: 1,
                          mr: 1,
                          bgcolor: theme.palette.primary.main,
                        }}
                      >
                        {index === steps.length - 1
                          ? t("Check Eligibility")
                          : t("Continue")}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("Back")}
                      </Button>
                    </div>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            {eligibilityResult && (
              <Box>
                {eligibilityResult.map((result, index) => (
                  <Alert key={index} severity="info" sx={{ mb: 2 }}>
                    {result}
                  </Alert>
                ))}
              </Box>
            )}
            <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
              {t("Reset")}
            </Button>
          </Paper>
        )}
      </Paper>
    </Box>
  );
};

export default LoanForgivenessChecker;
