import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import {
  Check,
  Target,
  AlertTriangle,
  TrendingUp,
  Shield,
  ChevronDown,
} from "lucide-react";
import { Link } from "react-router-dom";
import ProductShowcase from "./ProductShowcase";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#4ECDC4",
    },
    background: {
      default: "#F7FFF7",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#333333",
      secondary: "#555555",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.75rem",
      fontWeight: 700,
      marginBottom: "1rem",
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "2.25rem",
      fontWeight: 600,
      marginBottom: "1rem",
      color: "#222222",
    },
    h3: {
      fontSize: "1.875rem",
      fontWeight: 600,
      marginBottom: "1rem",
      color: "#222222",
    },
    body1: {
      fontSize: "1.125rem",
      lineHeight: 1.6,
    },
    body2: {
      fontSize: "1rem",
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "50px",
          padding: "12px 24px",
          fontSize: "1.125rem",
          fontWeight: 600,
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          transition: "all 0.3s ease",
          border: "2px solid #9ACD32",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 8px rgba(0,0,0,0.15)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
          transition: "all 0.3s ease",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 12px 25px rgba(0,0,0,0.15)",
          },
        },
      },
    },
  },
});

const HeroSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(6, 0),
  textAlign: "center",
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0),
}));

const FeatureIcon = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  color: theme.palette.secondary.main,
  background: theme.palette.background.paper,
  borderRadius: "50%",
  marginRight: theme.spacing(2),
}));

const LandingPage = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const features = [
    "Compare US loans: Federal Subsidized, Unsubsidized, PLUS, and Private loans",
    "Analyze UK loans: Plan 1, Plan 2, and Postgraduate Loans",
    "Customize repayment plans: Standard, Graduated, Extended, and Income-Driven (US) / Income-Contingent (UK)",
    "Adjust loan terms, interest rates, and grace periods",
    "Calculate based on your expected annual salary and country-specific thresholds",
  ];

  const calculatorTools = [
    { icon: <Target size={24} />, text: "Loan Comparison (US & UK)" },
    { icon: <AlertTriangle size={24} />, text: "Financial Impact Analysis" },
    {
      icon: <TrendingUp size={24} />,
      text: "Refinancing Optimizer (US) / Early Repayment (UK)",
    },
    {
      icon: <Shield size={24} />,
      text: "Forgiveness Checker (US) / Write-off Estimator (UK)",
    },
  ];

  const testimonials = [
    {
      name: "Emily R.",
      story:
        "Thanks to CashFlowTime, I discovered I could save $10,000 in interest by optimizing my repayment strategy. The stress test feature gave me peace of mind knowing I could handle unexpected financial changes.",
    },
    {
      name: "Alex T.",
      story:
        "CashFlowTime's refinancing simulator showed me how to pay off my loans 2 years early. The visual charts made it easy to understand my options and make an informed decision.",
    },
    {
      name: "Sarah L.",
      story:
        "As a financial advisor, I recommend CashFlowTime to all my clients with student loans. Its comprehensive features and user-friendly interface make it the best tool on the market for loan management.",
    },
  ];

  const objections = [
    {
      objection: "Is it complicated to use?",
      response:
        "Not at all! Our intuitive interface is designed for quick learning. Most users master it in under 10 minutes.",
    },
    {
      objection: "Does it work for my loans?",
      response:
        "Yes! We cover all types of student loans in the US and UK, including federal, private, and international student loans.",
    },
    {
      objection: "Is my data safe?",
      response:
        "Absolutely. Your privacy is our priority. You can use the calculator without creating an account or sharing sensitive data.",
    },
    {
      objection: "How accurate are the calculations?",
      response:
        "Our calculator uses up-to-date rates and formulas to provide the most accurate information possible. We regularly update our system to reflect the latest loan terms and conditions.",
    },
    {
      objection: "What if my financial situation changes?",
      response:
        "Our stress test feature allows you to simulate various financial scenarios, helping you prepare for potential changes in your situation.",
    },
    {
      objection: "Can I compare multiple repayment strategies?",
      response:
        "Absolutely! Our tool allows you to compare various repayment plans side-by-side, helping you find the strategy that works best for your unique situation.",
    },
  ];

  const faqs = [
    {
      question:
        "How does CashFlowTime differ from other student loan calculators?",
      answer:
        "CashFlowTime offers a comprehensive suite of tools that go beyond basic calculations. We provide in-depth analysis for both US and UK loan systems, including federal, private, and international loans. Our calculator allows you to compare up to 5 loan options side-by-side, customize repayment plans (Standard, Graduated, Extended, and Income-Driven/Income-Contingent), and adjust loan terms, interest rates, and grace periods. We also offer unique features like a stress test simulator, refinancing optimizer, and a forgiveness checker/write-off estimator. With CashFlowTime, you can visualize your financial future through detailed projections and make informed decisions based on your specific circumstances and country-specific thresholds.",
    },
    {
      question: "Is CashFlowTime free to use?",
      answer:
        "Yes, our basic calculator is completely free to use. We also offer premium features for a small fee, but you can access core functionality without any cost.",
    },
    {
      question: "Can I use CashFlowTime if I'm an international student?",
      answer:
        "Absolutely! While we specialize in US and UK loans, our calculator can handle international student loans and provide valuable insights for students from any country.",
    },
    {
      question: "Does CashFlowTime offer personalized advice?",
      answer:
        "While we don't provide personalized financial advice, our tool gives you all the information you need to make informed decisions about your student loans. For specific advice, we recommend consulting with a financial advisor.",
    },
  ];

  const buttonStyle = isMobile
    ? {
        py: 1.5,
        px: 2,
        fontSize: "1.0625rem",
      }
    : {
        py: 1.5,
        px: 4,
        fontSize: "1.125rem",
      };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
        <HeroSection>
          <Container maxWidth="md">
            <Typography variant="h1" gutterBottom>
              Master Your Student Loans in Minutes
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.25rem", mb: 4, color: "text.secondary" }}
            >
              Our intuitive calculator empowers you to optimize repayment
              strategies, explore refinancing options, and save thousands on
              interest - all in one place.
            </Typography>
            <Button
              component={Link}
              to="/advanced-calculator"
              variant="contained"
              color="primary"
              size="large"
              sx={buttonStyle}
            >
              Start Saving on Your Loans - It's Free
            </Button>
          </Container>
        </HeroSection>

        <Section>
          <ProductShowcase />
        </Section>

        <Section>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" gutterBottom>
                  Comprehensive Loan Analysis
                </Typography>
                <List>
                  {features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <Check color={theme.palette.secondary.main} />
                      </ListItemIcon>
                      <ListItemText
                        primary={feature}
                        primaryTypographyProps={{
                          style: {
                            fontWeight: 500,
                            color: theme.palette.text.primary,
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                        color: theme.palette.text.primary,
                      }}
                    >
                      Featured Calculator Tools
                    </Typography>
                    <Grid container spacing={2}>
                      {calculatorTools.map((tool, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <Box display="flex" alignItems="center">
                            <FeatureIcon>{tool.icon}</FeatureIcon>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 500,
                                color: theme.palette.text.primary,
                              }}
                            >
                              {tool.text}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Section>

        <Section sx={{ bgcolor: "background.paper" }}>
          <Container maxWidth="md">
            <Typography variant="h2" align="center" gutterBottom>
              What Our Users Say
            </Typography>
            <Grid container spacing={4}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{
                          fontStyle: "italic",
                          color: theme.palette.text.primary,
                        }}
                      >
                        "{testimonial.story}"
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: theme.palette.text.primary,
                        }}
                      >
                        - {testimonial.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Section>

        <Section>
          <Container maxWidth="md">
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              sx={{ color: "#000000", fontWeight: 700 }}
            >
              Common Concerns Addressed
            </Typography>
            <Grid container spacing={3}>
              {objections.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                          color: theme.palette.primary.main,
                        }}
                      >
                        {item.objection}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        {item.response}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Section>

        <Section sx={{ bgcolor: "background.paper" }}>
          <Container maxWidth="md">
            <Typography variant="h2" align="center" gutterBottom>
              Frequently Asked Questions
            </Typography>
            {faqs.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ChevronDown />}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600, color: theme.palette.text.primary }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Section>

        <Section>
          <Container maxWidth="md" sx={{ textAlign: "center" }}>
            <Typography variant="h3" gutterBottom>
              Ready to Take Control of Your Student Loans?
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ mb: 4, color: theme.palette.text.secondary }}
            >
              Join thousands of students who've optimized their repayment
              strategy and saved money on interest.
            </Typography>
            <Button
              component={Link}
              to="/advanced-calculator"
              variant="contained"
              color="primary"
              size="large"
              sx={buttonStyle}
            >
              Get Your Free Loan Analysis Now
            </Button>
          </Container>
        </Section>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
