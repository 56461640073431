import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FeedbackForm from "./FeedbackForm"; // Import the FeedbackForm component

const NotificationBar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenFeedback = () => {
    setFeedbackOpen(true);
  };

  const handleCloseFeedback = () => {
    setFeedbackOpen(false);
  };

  if (!isOpen) return null;

  return (
    <>
      <Box
        sx={{
          bgcolor: "#e8f7ff",
          color: "black",
          py: 1,
          px: 2,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", mb: isMobile ? 1 : 0 }}
        >
          CashFlowTime is in beta! Help us improve by leaving your feedback on
          <a
            target="_blank"
            rel="noreferrer"
            href="https://x.com/JustGetStart_d"
          >
            {" "}
            twitter
          </a>
          . Your insights are pure gold to us! 💎
        </Typography>
        <Box>
          {/* <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ mr: 1, mb: isMobile ? 1 : 0 }}
            onClick={handleOpenFeedback}
          >
            Give Feedback
          </Button> */}
          <IconButton color="primary" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <FeedbackForm open={feedbackOpen} onClose={handleCloseFeedback} />
    </>
  );
};

export default NotificationBar;
