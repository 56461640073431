import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Refinancing = ({ loanData, calculatedResults }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [refinancedLoan, setRefinancedLoan] = useState({
    loanAmount: loanData.loanAmount,
    interestRate: "",
    loanTerm: "",
  });
  const [refinancedResults, setRefinancedResults] = useState(null);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat(
      loanData.country === "USA" ? "en-US" : "en-GB",
      {
        style: "currency",
        currency: loanData.country === "USA" ? "USD" : "GBP",
      }
    ).format(amount);
  };

  const handleInputChange = (e) => {
    setRefinancedLoan({ ...refinancedLoan, [e.target.name]: e.target.value });
  };

  const calculateRefinancing = () => {
    const monthlyRate = refinancedLoan.interestRate / 100 / 12;
    const numberOfPayments = refinancedLoan.loanTerm * 12;
    const monthlyPayment =
      (refinancedLoan.loanAmount *
        monthlyRate *
        Math.pow(1 + monthlyRate, numberOfPayments)) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    const totalRepayment = monthlyPayment * numberOfPayments;
    const totalInterest = totalRepayment - refinancedLoan.loanAmount;

    setRefinancedResults({
      monthlyPayment,
      totalRepayment,
      totalInterest,
    });
  };

  const chartData = [
    {
      name: t("Monthly Payment"),
      current: calculatedResults.monthlyPayment,
      refinanced: refinancedResults ? refinancedResults.monthlyPayment : 0,
    },
    {
      name: t("Total Repayment"),
      current: calculatedResults.totalRepayment,
      refinanced: refinancedResults ? refinancedResults.totalRepayment : 0,
    },
    {
      name: t("Total Interest"),
      current: calculatedResults.totalInterest,
      refinanced: refinancedResults ? refinancedResults.totalInterest : 0,
    },
  ];

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {t("Loan Refinancing")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: theme.palette.background.paper }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Enter Refinanced Loan Details")}
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              name="interestRate"
              label={t("New Interest Rate (%)")}
              value={refinancedLoan.interestRate}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              name="loanTerm"
              label={t("New Loan Term (years)")}
              value={refinancedLoan.loanTerm}
              onChange={handleInputChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={calculateRefinancing}
              sx={{ mt: 2 }}
            >
              {t("Calculate Refinancing")}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: theme.palette.background.paper }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Refinancing Results")}
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Metric")}</TableCell>
                    <TableCell>{t("Current Loan")}</TableCell>
                    <TableCell>{t("Refinanced Loan")}</TableCell>
                    <TableCell>{t("Difference")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t("Loan Amount")}</TableCell>
                    <TableCell>{formatCurrency(loanData.loanAmount)}</TableCell>
                    <TableCell>
                      {formatCurrency(refinancedLoan.loanAmount)}
                    </TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Interest Rate")}</TableCell>
                    <TableCell>{loanData.interestRate}%</TableCell>
                    <TableCell>{refinancedLoan.interestRate}%</TableCell>
                    <TableCell>
                      {(
                        refinancedLoan.interestRate - loanData.interestRate
                      ).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Loan Term")}</TableCell>
                    <TableCell>
                      {loanData.loanTerm} {t("years")}
                    </TableCell>
                    <TableCell>
                      {refinancedLoan.loanTerm} {t("years")}
                    </TableCell>
                    <TableCell>
                      {refinancedLoan.loanTerm - loanData.loanTerm} {t("years")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Monthly Payment")}</TableCell>
                    <TableCell>
                      {formatCurrency(calculatedResults.monthlyPayment)}
                    </TableCell>
                    <TableCell>
                      {refinancedResults
                        ? formatCurrency(refinancedResults.monthlyPayment)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {refinancedResults
                        ? formatCurrency(
                            refinancedResults.monthlyPayment -
                              calculatedResults.monthlyPayment
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Total Repayment")}</TableCell>
                    <TableCell>
                      {formatCurrency(calculatedResults.totalRepayment)}
                    </TableCell>
                    <TableCell>
                      {refinancedResults
                        ? formatCurrency(refinancedResults.totalRepayment)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {refinancedResults
                        ? formatCurrency(
                            refinancedResults.totalRepayment -
                              calculatedResults.totalRepayment
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Total Interest")}</TableCell>
                    <TableCell>
                      {formatCurrency(calculatedResults.totalInterest)}
                    </TableCell>
                    <TableCell>
                      {refinancedResults
                        ? formatCurrency(refinancedResults.totalInterest)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {refinancedResults
                        ? formatCurrency(
                            refinancedResults.totalInterest -
                              calculatedResults.totalInterest
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: theme.palette.background.paper }}
          >
            <Box height={isMobile ? 300 : 400}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip formatter={(value) => formatCurrency(value)} />
                  <Legend />
                  <Bar
                    dataKey="current"
                    name={t("Current Loan")}
                    fill="#0088FE"
                  />
                  <Bar
                    dataKey="refinanced"
                    name={t("Refinanced Loan")}
                    fill="#00C49F"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Refinancing;
