import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const FinancialImpact = ({ loanData, calculatedResults }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [monthlyExpenses, setMonthlyExpenses] = useState([
    {
      name: t("Loan Payment"),
      amount: calculatedResults.monthlyPayment,
      category: "Debt",
    },
    { name: t("Rent/Mortgage"), amount: 0, category: "Debt" },
    { name: t("Utilities"), amount: 0, category: "Expenses" },
    { name: t("Food"), amount: 0, category: "Expenses" },
    { name: t("Transportation"), amount: 0, category: "Expenses" },
    { name: t("Insurance"), amount: 0, category: "Expenses" },
    { name: t("Entertainment"), amount: 0, category: "Expenses" },
    { name: t("Other Debts"), amount: 0, category: "Debt" },
  ]);

  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const [financialGoal, setFinancialGoal] = useState("");
  const [goalAmount, setGoalAmount] = useState(0);
  const [calculatedData, setCalculatedData] = useState(null);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat(
      loanData.country === "USA" ? "en-US" : "en-GB",
      {
        style: "currency",
        currency: loanData.country === "USD" ? "USD" : "GBP",
      }
    ).format(amount);
  };

  const handleExpenseChange = (index, value) => {
    const updatedExpenses = [...monthlyExpenses];
    updatedExpenses[index].amount = parseFloat(value) || 0;
    setMonthlyExpenses(updatedExpenses);
  };

  const calculateFinancialData = () => {
    const totalMonthlyExpenses = monthlyExpenses.reduce(
      (sum, expense) => sum + expense.amount,
      0
    );
    const disposableIncome = monthlyIncome - totalMonthlyExpenses;
    const savings = Math.max(disposableIncome, 0); // Ensure savings is not negative
    const debtToIncomeRatio = totalMonthlyExpenses / monthlyIncome;

    const expensesByCategory = monthlyExpenses.reduce((acc, expense) => {
      if (!acc[expense.category]) {
        acc[expense.category] = 0;
      }
      acc[expense.category] += expense.amount;
      return acc;
    }, {});

    const chartData = [
      { name: "Income", value: monthlyIncome, fill: "#4CAF50" }, // Green
      {
        name: "Expenses",
        value: expensesByCategory.Expenses || 0,
        fill: "#F44336",
      }, // Red
      { name: "Debt", value: expensesByCategory.Debt || 0, fill: "#2196F3" }, // Blue
      { name: "Savings", value: savings, fill: "#FFC107" }, // Yellow
    ];

    const financialHealthScore = getFinancialHealthScore(
      debtToIncomeRatio,
      savings / monthlyIncome,
      disposableIncome
    );

    setCalculatedData({
      totalMonthlyExpenses,
      disposableIncome,
      savings,
      debtToIncomeRatio,
      chartData,
      financialHealthScore,
    });
  };

  const getFinancialHealthScore = (
    debtToIncomeRatio,
    savingsRate,
    disposableIncome
  ) => {
    let score = 0;
    if (debtToIncomeRatio <= 0.3) score += 30;
    else if (debtToIncomeRatio <= 0.4) score += 15;

    if (savingsRate >= 0.2) score += 30;
    else if (savingsRate >= 0.1) score += 15;

    if (disposableIncome > 0) score += 20;

    // Add score based on emergency fund
    const emergencyFund = savingsRate * 6; // 6 months of expenses
    if (emergencyFund >= 1) score += 20;
    else if (emergencyFund >= 0.5) score += 10;

    return score;
  };

  const getImpactLevel = (ratio) => {
    if (ratio <= 0.3) return t("Healthy");
    if (ratio <= 0.4) return t("Moderate");
    return t("High");
  };

  const getTailoredRecommendations = () => {
    if (!calculatedData) return [];

    const recommendations = [];
    if (calculatedData.debtToIncomeRatio > 0.4) {
      recommendations.push(
        t(
          "Your debt-to-income ratio is high. Consider focusing on debt repayment and exploring debt consolidation options."
        )
      );
    }
    const savingsRate = calculatedData.savings / monthlyIncome;
    if (savingsRate < 0.1) {
      recommendations.push(
        t(
          "Your savings rate is low. Aim to save at least 10% of your income. Look for areas to cut expenses and increase your savings."
        )
      );
    }
    const expensesRate =
      (calculatedData.chartData.find((item) => item.name === "Expenses")
        ?.value || 0) / monthlyIncome;
    if (expensesRate > 0.5) {
      recommendations.push(
        t(
          "Your expenses are high relative to your income. Review your budget and look for areas to reduce spending."
        )
      );
    }

    // Emergency fund recommendation
    const emergencyFund =
      calculatedData.savings / calculatedData.totalMonthlyExpenses;
    if (emergencyFund < 3) {
      recommendations.push(
        t(
          "Your emergency fund is low. Aim to save 3-6 months of expenses for unexpected situations."
        )
      );
    }

    // Country-specific recommendations
    if (loanData.country === "USA") {
      recommendations.push(
        t(
          "Consider maximizing contributions to tax-advantaged accounts like 401(k)s and IRAs for long-term savings."
        )
      );
    } else {
      recommendations.push(
        t(
          "Look into ISAs and pension schemes for tax-efficient long-term savings."
        )
      );
    }

    return recommendations;
  };

  const MetricInfo = ({ title, description }) => (
    <Tooltip title={description}>
      <IconButton size="small">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {t("Financial Impact Analysis")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: theme.palette.background.paper }}
          >
            <Typography variant="h6" gutterBottom>
              {t("Monthly Income and Expenses")}
            </Typography>
            <TextField
              fullWidth
              label={t("Monthly Income")}
              type="number"
              value={monthlyIncome}
              onChange={(e) =>
                setMonthlyIncome(parseFloat(e.target.value) || 0)
              }
              sx={{ mb: 2 }}
            />
            <Grid container spacing={2}>
              {monthlyExpenses.map((expense, index) => (
                <Grid item xs={12} sm={6} key={expense.name}>
                  <TextField
                    fullWidth
                    label={expense.name}
                    type="number"
                    value={expense.amount}
                    onChange={(e) => handleExpenseChange(index, e.target.value)}
                    disabled={expense.name === t("Loan Payment")}
                  />
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={calculateFinancialData}
              sx={{ mt: 2 }}
            >
              {t("Calculate Financial Impact")}
            </Button>
          </Paper>
        </Grid>
        {calculatedData && (
          <>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{ p: 2, bgcolor: theme.palette.background.paper }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("Financial Breakdown")}
                </Typography>
                <Box height={300}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={calculatedData.chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <RechartsTooltip
                        formatter={(value) => formatCurrency(value)}
                      />
                      <Legend />
                      <Bar dataKey="value" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{ p: 2, bgcolor: theme.palette.background.paper }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("Financial Health Score")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h3"
                    color={
                      calculatedData.financialHealthScore > 70
                        ? "success.main"
                        : calculatedData.financialHealthScore > 40
                        ? "warning.main"
                        : "error.main"
                    }
                  >
                    {calculatedData.financialHealthScore}/100
                  </Typography>
                  <Typography variant="body1">
                    {calculatedData.financialHealthScore > 70
                      ? t("Excellent financial health!")
                      : calculatedData.financialHealthScore > 40
                      ? t("Your financial health needs some improvement.")
                      : t(
                          "Your financial health requires immediate attention."
                        )}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{ p: 2, bgcolor: theme.palette.background.paper }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("Financial Impact Overview")}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("Metric")}</TableCell>
                        <TableCell>{t("Value")}</TableCell>
                        <TableCell>{t("Impact")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {t("Debt-to-Income Ratio")}
                          <MetricInfo
                            title={t("Debt-to-Income Ratio")}
                            description={t(
                              "The percentage of your monthly income that goes towards debt payments. Lower is better."
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          {(calculatedData.debtToIncomeRatio * 100).toFixed(2)}%
                        </TableCell>
                        <TableCell>
                          {getImpactLevel(calculatedData.debtToIncomeRatio)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {t("Monthly Disposable Income")}
                          <MetricInfo
                            title={t("Monthly Disposable Income")}
                            description={t(
                              "The money left over after paying all your monthly expenses. Higher is better."
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          {formatCurrency(calculatedData.disposableIncome)}
                        </TableCell>
                        <TableCell>
                          {calculatedData.disposableIncome < 0
                            ? t("Negative")
                            : t("Positive")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {t("Loan Payoff Year")}
                          <MetricInfo
                            title={t("Loan Payoff Year")}
                            description={t(
                              "The year when your loan will be fully paid off."
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          {new Date().getFullYear() +
                            Math.ceil(loanData.loanTerm)}
                        </TableCell>
                        <TableCell>
                          {loanData.loanTerm > 10
                            ? t("Long-term Impact")
                            : t("Short-term Impact")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {t("Total Interest Paid")}
                          <MetricInfo
                            title={t("Total Interest Paid")}
                            description={t(
                              "The total amount of interest you'll pay over the life of the loan."
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          {formatCurrency(calculatedResults.totalInterest)}
                        </TableCell>
                        <TableCell>
                          {calculatedResults.totalInterest > loanData.loanAmount
                            ? t("High")
                            : t("Moderate")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{ p: 2, bgcolor: theme.palette.background.paper }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("Tailored Recommendations")}
                </Typography>
                <ul>
                  {getTailoredRecommendations().map((recommendation, index) => (
                    <li key={index}>{recommendation}</li>
                  ))}
                </ul>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{ p: 2, bgcolor: theme.palette.background.paper }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("Set a Financial Goal")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Select
                      fullWidth
                      value={financialGoal}
                      onChange={(e) => setFinancialGoal(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        {t("Select Goal Type")}
                      </MenuItem>
                      <MenuItem value="emergency_fund">
                        {t("Build Emergency Fund")}
                      </MenuItem>
                      <MenuItem value="debt_payoff">
                        {t("Pay Off Debt Early")}
                      </MenuItem>
                      <MenuItem value="savings">
                        {t("Save for Large Purchase")}
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label={t("Goal Amount")}
                      value={goalAmount}
                      onChange={(e) =>
                        setGoalAmount(parseFloat(e.target.value) || 0)
                      }
                    />
                  </Grid>
                </Grid>
                {financialGoal && goalAmount > 0 && calculatedData && (
                  <Box mt={2}>
                    <Typography variant="body1">
                      {t("Time to reach goal")}:{" "}
                      {Math.ceil(
                        goalAmount /
                          Math.max(calculatedData.disposableIncome, 1)
                      )}{" "}
                      {t("months")}
                    </Typography>
                    <Typography variant="body2">
                      {t("Adjust your expenses to reach your goal faster!")}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default FinancialImpact;
