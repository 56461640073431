import React from "react";
import { Box, Container, Typography, Link, Grid, SvgIcon } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const CashFlowTimeLogo = ({ size = 40, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 200 50"
    style={{ fontSize: size, width: "auto", height: "auto" }}
  >
    <text
      x="10"
      y="35"
      fontFamily="Arial"
      fontSize="24"
      fontWeight="bold"
      fill="#4A54EB"
    >
      Cash
    </text>
    <text
      x="70"
      y="35"
      fontFamily="Arial"
      fontSize="24"
      fontWeight="bold"
      fill="#000"
    >
      Flow
    </text>
    <text
      x="130"
      y="35"
      fontFamily="Arial"
      fontSize="24"
      fontWeight="bold"
      fill="#4A54EB"
    >
      Time
    </text>
  </SvgIcon>
);

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "background.paper",
        py: 6,
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <CashFlowTimeLogo size={40} />
            </RouterLink>
            <Typography variant="body2" color="text.secondary">
              Empowering your financial decisions
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Features
            </Typography>
            <Link
              component={RouterLink}
              to="/advanced-calculator"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              Advanced Calculator
            </Link>
            <Link
              component={RouterLink}
              to="/budget-calculator"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              Budget Calculator
            </Link>
            <Link
              component={RouterLink}
              to="/loan-fun-facts"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              Loan Fun Facts
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Company
            </Typography>
            <Link
              component={RouterLink}
              to="/about"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              About Us
            </Link>
            <Link
              component={RouterLink}
              to="/contact"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              Contact
            </Link>
            <Link
              component={RouterLink}
              to="/careers"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              Careers
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Legal
            </Typography>
            <Link
              component={RouterLink}
              to="/privacy"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
            <Link
              component={RouterLink}
              to="/terms"
              color="inherit"
              display="block"
              sx={{ mb: 1, textDecoration: "none" }}
            >
              Terms of Service
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} CashFlowTime. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
