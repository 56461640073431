import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Rating,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import emailjs from "@emailjs/browser";

const FeedbackForm = ({ open, onClose }) => {
  const [rating, setRating] = useState(0);
  const [easeOfUse, setEaseOfUse] = useState("");
  const [improvements, setImprovements] = useState("");
  const [personalFeedback, setPersonalFeedback] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = (e) => {
    e.preventDefault();

    // emailjs
    //   .send(
    //     "YOUR_SERVICE_ID",
    //     "YOUR_TEMPLATE_ID",
    //     {
    //       rating,
    //       easeOfUse,
    //       improvements,
    //       personalFeedback,
    //     },
    //     "YOUR_USER_ID"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       onClose();
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          Help Us Improve CashFlowTime! 🚀
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Your feedback is the secret sauce to our success. Let's make
          CashFlowTime finger-lickin' good!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <Typography component="legend">
              How would you rate your experience?
            </Typography>
            <Rating
              name="rating"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              size={isMobile ? "small" : "medium"}
            />
          </Box>

          <FormControl component="fieldset" margin="normal" fullWidth>
            <FormLabel component="legend">
              How easy was CashFlowTime to use?
            </FormLabel>
            <RadioGroup
              aria-label="ease-of-use"
              name="ease-of-use"
              value={easeOfUse}
              onChange={(e) => setEaseOfUse(e.target.value)}
            >
              <FormControlLabel
                value="very-easy"
                control={<Radio size={isMobile ? "small" : "medium"} />}
                label="Easier than finding a matching sock!"
              />
              <FormControlLabel
                value="easy"
                control={<Radio size={isMobile ? "small" : "medium"} />}
                label="Pretty smooth sailing"
              />
              <FormControlLabel
                value="neutral"
                control={<Radio size={isMobile ? "small" : "medium"} />}
                label="It was okay, I guess"
              />
              <FormControlLabel
                value="difficult"
                control={<Radio size={isMobile ? "small" : "medium"} />}
                label="Bit of a head-scratcher"
              />
              <FormControlLabel
                value="very-difficult"
                control={<Radio size={isMobile ? "small" : "medium"} />}
                label="Harder than explaining memes to my grandma"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            margin="normal"
            fullWidth
            label="What improvements would you suggest?"
            multiline
            rows={isMobile ? 2 : 3}
            value={improvements}
            onChange={(e) => setImprovements(e.target.value)}
            placeholder="Don't hold back, we can take it!"
          />

          <TextField
            margin="normal"
            fullWidth
            label="Any other thoughts? Let it all out!"
            multiline
            rows={isMobile ? 3 : 4}
            value={personalFeedback}
            onChange={(e) => setPersonalFeedback(e.target.value)}
            placeholder="This is a judgment-free zone. Unless you like pineapple on pizza, then we might judge a little."
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Maybe Later
        </Button>
        <Button onClick={handleSubmit} color="secondary" variant="contained">
          Submit Feedback
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackForm;
